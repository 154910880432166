import React from 'react';

function Header() {
  return (
    <header className="bg-gray-800 text-white shadow-md">
      <div className="container mx-auto px-4 py-6 flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <img src="/quikdivelogohd.png" alt="QuikDive" className="h-14" />
          <h1 className="text-2xl font-semibold">QuikDive</h1>
        </div>
        <nav>
          <ul className="flex space-x-6">
            <li>
              <a href="#features" className="text-xl hover:text-blue-400 transition-colors duration-200">
                Features
              </a>
            </li>
            <li>
              <a href="#cta" className="text-xl hover:text-blue-400 transition-colors duration-200">
                Join Us
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;