import React from 'react';

const features = [
  {
    title: 'Instant Gaming',
    description: 'Jump into games without any loading delays.',
    icon: '/feature-icons/instantgaming.webp',
  },
  {
    title: 'Game Submission',
    description: 'Easily submit your own games to the platform.',
    icon: '/feature-icons/submission.png',
  },
  {
    title: 'Revenue Sharing',
    description: 'Earn money through ad revenue from your game submissions.',
    icon: '/feature-icons/revenuesharing.webp',
  },
  {
    title: 'Educational Goals',
    description: 'Learn programming logic in a fun and engaging way through our platform.',
    icon: '/feature-icons/education.png',
  },
];

function Features() {
  return (
    <section id="features" className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">Key Features</h2>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="text-center">
              <div className="w-32 h-32 mx-auto mb-4">
                <img src={feature.icon} alt={feature.title} className="object-contain w-full h-full" />
              </div>
              <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Features;