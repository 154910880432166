import React from 'react';

function Hero() {
  return (
    <section className="bg-blue-500 text-white py-20">
      <div className="container mx-auto px-4 flex items-center">
        <div className="w-1/2">
          <h1 className="text-5xl font-bold mb-2">Welcome to QuikDive</h1>
          <p className="text-sm italic mb-6">Yes, with a 'k'!</p>
          <p className="text-xl mb-8">Instant gaming experiences with zero loading time. Create, play, and earn!</p>
          <p className="text-xl mb-8">We are launching in 2025, but you can join the waitlist now!</p>
          <a href="https://forms.gle/VQ6wzXreZsCFniZx7" className="bg-white text-blue-500 px-6 py-3 rounded-full font-semibold hover:bg-blue-100 transition duration-300">Join the Waitlist</a>
        </div>
        <div className="w-1/2">
          <img src="/heroimage_site.jpg" alt="QuikDive Gaming" className="w-full rounded-lg shadow-lg" />
        </div>
      </div>
    </section>
  );
}

export default Hero;