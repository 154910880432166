import React from 'react';

function CallToAction() {
  return (
    <section id="cta" className="bg-blue-600 text-white py-20">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-3xl font-bold mb-6">Join Us at QuikDive!</h2>
        <p className="text-xl mb-8">We are looking for collaborators and supporters. Reach out to us at <a href="mailto:info@quikdive.com" className="underline">info@quikdive.com</a>.</p>
        <a href="mailto:info@quikdive.com" className="bg-white text-blue-600 px-8 py-3 rounded-full font-semibold text-lg hover:bg-blue-100 transition duration-300">Contact Us</a>
      </div>
    </section>
  );
}

export default CallToAction;