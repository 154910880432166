import React from 'react';

function Footer() {
  return (
    <footer className="bg-gray-800 text-white py-8">
      <div className="container mx-auto px-4 text-center">
        <p>2024 QuikDive</p>
        <div className="mt-4">
          {/* <a href="#" className="text-blue-300 hover:text-blue-100 mx-2">Privacy Policy</a>
          <a href="#" className="text-blue-300 hover:text-blue-100 mx-2">Terms of Service</a> */}
          <a href="mailto:info@quikdive.com" className="text-blue-300 hover:text-blue-100 mx-2">Contact Us</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
